<template>
  <div class="index-wrapper" ref="index" >
    <div class="index-inner">
      <p-header></p-header>
      <slider>
        <div class="banner" style="cursor:pointer" @click="tapVideo"><img src="~common/images/banner_2.png" width="100%" alt=""></div>
        <div class="banner"><img src="~common/images/banner_1.png" width="100%" alt=""><div class="usebtn" @click="tapUseBanner">免费试用</div></div>
        <div class="banner"><img src="~common/images/banner_7.png" width="100%" alt=""><div class="usebtn" @click="tapUseBanner">免费试用</div></div>
        <div class="banner"><img src="~common/images/banner_6.png" width="100%" alt=""><div class="usebtn" @click="tapUseBanner">免费试用</div></div>
        <!-- <div class="banner"><img src="~common/images/banner_3.png" width="100%" alt=""><div class="usebtn" @click="tapUseBanner">免费试用</div></div>
        <div class="banner"><img src="~common/images/banner_4.png" width="100%" alt=""><div class="usebtn" @click="tapUseBanner">免费试用</div></div>
        <div class="banner"><img src="~common/images/banner_5.png" width="100%" alt=""><div class="usebtn" @click="tapUseBanner">免费试用</div></div> -->
        
      </slider>
      <div class="index-container">
        <div class="desc-box">
          <div class="desc-box-item">
            <img src="~common/images/index_img_1.png" alt="" class="desc-img">
            <div class="title">二维码生成系统<br/>按需分配一目了然</div>
          </div>
          <div class="line"></div>
          <div class="desc-box-item">
            <img src="~common/images/index_img_2.png" alt="" class="desc-img">
            <div class="title">精选高效模板<br/>有效提升消费者互动</div>
          </div>
          <div class="line"></div>
          <div class="desc-box-item">
            <img src="~common/images/index_img_3.png" alt="" class="desc-img">
            <div class="title">营销活动秒上线<br/>3分钟完成活动搭建</div>
          </div>
          <div class="line"></div>
          <div class="desc-box-item">
            <img src="~common/images/index_img_4.png" alt="" class="desc-img">
            <div class="title">实时数据查询<br/>活动趋势了如指掌</div>
          </div>
        </div>
        <div class="case-box">
          <img src="~common/images/index_case_ic.png" alt="" class="case-ic">
          <div class="title">行业解决方案</div>
          <div class="subtitle"></div>
          <div class="content">
              <div class="item" @click="tapDetail(1)">
                <img src="~common/images/index_item_1_1.png" alt="" class="item-img">
                <div class="item-title">乳制品行业</div>
              </div>
            <div class="item" @click="tapDetail(2)">
              <img src="~common/images/index_item_2_1.png" alt="" class="item-img">
              <div class="item-title">饮料行业</div>
            </div>
            <div class="item" @click="tapDetail(3)">
              <img src="~common/images/index_item_3_1.png" alt="" class="item-img">
              <div class="item-title">酒水行业</div>
            </div>
          </div>
        </div>

        <div class="bottom-box">
          <img src="~common/images/index_bottom.png" alt="" width="100%">
        </div>

        <div class="check-box">
          <div class="title">免费搭建微信营销活动，快速提升销量增长</div>
          <div class="btn" @click="tapUse">免费试用</div>
        </div>
      </div>

      <p-footer></p-footer>
      <aside-bar ></aside-bar>
    </div>
    <div class="videoModeil" v-show="isShowVideo">
      <div class="videoDiv">
        <video 
          id="video"
          controls 
          playsinline 
          webkit-playsinline="webkit-playsinline"
          class="video" preload="auto"
                   poster="https://vod.greatdata.com.cn/snapshot/5ff550caddb047b0b52080cd01cd685a00002.jpg"
          src="https://vod.greatdata.com.cn/5ff550caddb047b0b52080cd01cd685a/e28d642eb0624576817be04331493730-5287d2089db37e62345123a1be272f8b.mp4"></video>
        <img src="~common/images/ic_close.png" class="close" @click="tapClose"/>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
  import PHeader from 'components/header/header'
  import PFooter from 'components/footer/footer'
  import Slider from 'components/slide/slide'
  import asideBar from 'components/asideBar/asideBar'

  export default {
    data() {
      return {
        isShowVideo: false
      }
    },
    created() {
    },
    activated() {
    },
    methods: {
      tapUse() {
        window._hmt.push(['_trackEvent', '首页底部', '免费试用']);
        window.open('https://www.smtyxy.com/wxapp/index.html')
      },
      tapDetail(id) {
        this.$router.push({
          name: 'indexDetail', query: {
            id: id
          }
        })
      },
      tapUseBanner() {
        window._hmt.push(['_trackEvent', '首页轮播图', '免费试用']);
        window.open('https://www.smtyxy.com/wxapp/index.html')
      },
      tapVideo() {
        this.isShowVideo = true
        document.getElementById("video").play()
      },
      tapClose() {
        document.getElementById("video").pause()
        this.isShowVideo = false
      }
    },
    components: {
      PHeader,
      Slider,
      PFooter,
      asideBar
    }
  }
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
  .index-wrapper
    width 100%
    min-width 1270px
    height 100%
    .videoModeil
      position fixed
      top 0
      left 0
      right 0
      bottom 0
      background-color: rgba(9, 0, 0, 0.61);
      z-index 999
      .videoDiv
        position absolute
        top 50%
        width 1250px
        left 50%
        transform: translate(-50%, -50%)
        height 703px
        background: #FFFFFF
        padding 10px
        border-radius: 12px;
        .video
          width: 100%
          height: 100%;
        .close
          position absolute
          top -20px
          right -20px
          width: 40px
          height 40px
          cursor pointer
    .index-inner
      padding-top: 0.35rem;
      position relative
      .banner
        position relative
        .usebtn
          cursor pointer
          position absolute
          left 1.98rem
          top 1.53rem
          width: 1.01rem;
          height: 0.33rem;
          background: linear-gradient(238deg, #17ABEB 0%, #5FC1FE 100%);
          border-radius: 0.04rem;
          font-size: 0.15rem;
          color: #FFFFFF;
          line-height: 0.33rem;
        .usebtn2
          cursor pointer
          position absolute
          left 1.98rem
          top 1.53rem
          width: 1.01rem;
          height: 0.33rem;
          background: #FFFFFF;
          border-radius: 0.04rem;
          font-size: 0.15rem;
          color: #666666;
          line-height: 0.33rem;
    .index-container
      width 100%
      .bg-gray
        background #fbfcfd
    .case-box
      background: #F1FAFC;
      padding-top: 0.21rem
      padding-bottom: 0.47rem
      text-align: center
      width: 100%
      position: relative
      .case-ic
        width: 0.14rem
        height: 0.16rem
        position: absolute
        top: 0.21rem;
        left: 38.5%
      .title
        font-size: 0.16rem;
        font-weight: bold;
        color: #191B31;
        letter-spacing: 1px;
      .subtitle
        font-size: 0.09rem;
        font-weight: bold;
        color: #191B31;
        letter-spacing: 1px;
        margin-top: 0.04rem
        margin-bottom: 0.39rem
      .content
        display: flex;
        flex-direction: row;
        justify-content: center;
        .item
          padding 0
          cursor pointer
          .item-img
            margin-right: 0.15rem
            width: 1.77rem
            height: 1.91rem
          .item-title
            margin-top: 0.21rem
            font-size: 0.09 rem;
            font-weight: bold;
            color: #333333;
            line-height: 0.15rem;
            text-align: left
    .desc-box
      width: 100%
      font-weight: 400;
      color: #333333;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-top: 0.32rem
      padding-bottom: 0.32rem
      text-align: center;
      .desc-box-item
        display block
        .desc-img
          width: 0.83rem
          height: 0.8rem
          margin-top: 0.05rem
        .title
          text-align: center;
          margin-top: 0.04rem
          font-size: 0.09rem;
          font-weight: 400;
          color: #333333;
          line-height: 0.14rem;
      .line
        display: inline;
        width: 0.01rem;
        height: 0.52rem;
        background: #C6D0D8;
        margin: 0.18rem 0.34rem 0rem 0.34rem;
    .bottom-box
      padding-bottom .47rem
    .check-box
      background: #F1FAFC;
      height: 0.54rem
      font-size: 0.16rem;
      font-weight: bold;
      color: #191B31;
      line-height: 0.54rem;
      padding-left: 2.21rem
      .title
        display: inline
        font-size: 0.16rem;
      .btn
        cursor pointer
        display: inline-block
        width: 0.84rem;
        height: 0.29rem;
        background: #FF9D44;
        border-radius: 0.04rem;
        font-size: 0.09rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 0.29rem;
        text-align: center
        margin-left: 1.8rem
</style>
